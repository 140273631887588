import React, { useState, useRef, useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';

const useSelectableRows = ({
  selectable,
  thead,
  tbody,
  rowData,
  columnData,
  onSelect,
}) => {
  const [selected, setSelected] = useState([]);
  const [multiSelectedState, setMultiSelectedState] = useState({
    checked: false,
  });
  const rowKeys = useRef();

  useEffect(() => {
    if (selectable) {
      if (selected.length === rowKeys.current.length) {
        setMultiSelectedState({ checked: true, indeterminate: false });
      }
      if (selected.length === 0) {
        setMultiSelectedState({ checked: false, indeterminate: false });
      }
      if (selected.length > 0 && selected.length < rowKeys.current.length) {
        setMultiSelectedState({ indeterminate: true });
      }
    }
  }, [selectable, selected.length]);

  const updateSelected = (key, { checked }) => {
    const s = selected.slice();
    if (!checked) {
      s.splice(
        s.findIndex((sel) => sel === key),
        1,
      );
    } else {
      s.push(key);
    }
    setSelected(s);
    onSelect && onSelect(s);
  };

  const handleMultiCheckbox = (checked) => {
    const s = checked ? rowKeys.current : [];
    setSelected(s);
    onSelect && onSelect(s);
  };

  const addCheckboxToJSX = (tableSection, isHeader = false) => {
    const wrapper = isHeader ? 'th' : 'td';
    const keys = [];
    const rows = React.Children.map(
      tableSection.props.children,
      (row, rowIndex) => {
        !isHeader && keys.push(row.key);
        const cells = React.Children.toArray(row.props.children);
        const headerHandler = (e, { checked }) => {
          e.stopPropagation();
          handleMultiCheckbox(checked);
        };
        const rowHandler = (e, data) => {
          e.stopPropagation();
          updateSelected(row.key, data);
        };

        const clickHandler = isHeader ? headerHandler : rowHandler;
        const checkboxProps = isHeader
          ? { ...multiSelectedState }
          : { checked: selected.includes(row.key) };

        const cellId = !isHeader
          ? `simpletable-checkbox-${rowIndex}`
          : `simpletable-header-checkbox`;

        cells.splice(
          0,
          0,
          React.createElement(
            wrapper,
            {
              width: 25,
              ['data-testid']: cellId,
              key: cellId,
            },
            <Checkbox {...checkboxProps} onClick={clickHandler} />,
          ),
        );
        return React.cloneElement(row, { ...row.props }, cells);
      },
    );
    if (!isHeader) {
      rowKeys.current = keys;
    }
    return React.cloneElement(tableSection, { ...tableSection.props }, rows);
  };

  const generateRowsWithCheckbox = (rows) => {
    const keys = [];
    const trs = rows.map((r, rowIndex) => {
      const tds = r.map((item, tdIndex) =>
        typeof item === 'object' && item.hasOwnProperty('content') ? (
          <td key={tdIndex} {...item.props}>
            {item.content}
          </td>
        ) : (
          <td key={tdIndex}>{item}</td>
        ),
      );
      const key = r;
      const rowHandler = (e, data) => {
        e.stopPropagation();
        updateSelected(key, data);
      };
      const checkboxProps = { checked: selected.includes(key) };
      keys.push(key);
      tds.unshift(
        <td key={`simpletable-checkbox-${rowIndex}`} width={25}>
          <Checkbox {...checkboxProps} onClick={rowHandler} />
        </td>,
      );
      return <tr key={key}>{tds}</tr>;
    });
    rowKeys.current = keys;
    return <tbody>{trs}</tbody>;
  };

  if (selectable) {
    if (tbody) {
      tbody = addCheckboxToJSX(tbody, false);
    } else {
      tbody = generateRowsWithCheckbox(rowData);
    }
    if (thead) {
      thead = addCheckboxToJSX(thead, true);
    } else {
      columnData.unshift({
        label: 'checkbox',
        content: (
          <Checkbox
            {...multiSelectedState}
            onClick={(e, { checked }) => {
              e.stopPropagation();
              handleMultiCheckbox(checked);
            }}
          />
        ),
        width: 25,
        isStatic: true,
      });
    }
  }
  return {
    theadComponent: thead,
    tbodyComponent: tbody,
    rowData,
    columnData,
  };
};

export default useSelectableRows;
