import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import useMembers from "../data-hooks/useMembers";
import SimpleTable from "../components/SimpleTable/SimpleTable";

const columns = [
  { label: "Name", property: "name" },
  { label: "Born", property: (item) => new Date(item.born) },
  { label: "Died", property: (item) => new Date(item.died) },
  { label: "Cause of death", property: "cause", isStatic: true },
  { label: "Bio", property: "short", isStatic: true },
];

const IndexPage = () => {
  const members = useMembers();
  const [memberData, setMemberData] = React.useState(members);
  const [search, setSearch] = React.useState(null);
  const [sortFn, setSortFn] = React.useState(null);

  React.useEffect(() => {
    let data = members;
    if (sortFn) {
      data = members.slice().sort(sortFn);
    }
    setMemberData(data);
  }, [members, sortFn]);

  const sorter = (sortFunc) => {
    setSortFn(() => sortFunc);
  };

  const displayDate = (i) => {
    const d = new Date(i);
    return d.toLocaleDateString("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const displayData = search
    ? memberData.filter(
        (slice) =>
          slice.sliceName.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    : memberData;

  return (
    <Layout page="directory">
      <div className="flex flex-wrap">
        <SimpleTable
          className="directory-table hover striped sticky"
          style={{ top: 75 }}
          columns={columns}
          dataCount={displayData?.length}
          defaultSort={{ col: 1, order: "asc" }}
          sorter={sorter}
          tableKey="members-table"
        >
          <tbody>
            {displayData.map((m, i) => (
              <tr key={i}>
                <td><Link to={`/profile/${m.slug}`}>{m.name}</Link></td>
                <td>{displayDate(m.born)}</td>
                <td>{displayDate(m.died)}</td>
                <td>{m.cause}</td>
                <td>{m.short}</td>
              </tr>
            ))}
          </tbody>
        </SimpleTable>
      </div>
    </Layout>
  );
};

export default IndexPage;
