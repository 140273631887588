import { useStaticQuery, graphql } from "gatsby";
const useMembers = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            members {
              name
              born
              died
              short
              cause,
			  slug
            }
          }
        }
      }
    `
  );
  return data.site.siteMetadata.members;
};

export default useMembers;
