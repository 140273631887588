/*

****
Use with a Trigger and Content component.
****

<Popup>
		<Trigger>
				Here is the trigger, the thing you see first.
				The order of Trigger and Content does not matter.
		</Trigger>
		<Content>
				Here is the content, the stuff in the popup.
				The order of Trigger and Content does not matter.
		</Content>
</Popup>


****
Use with just props as string.
String values for props will always be wrapped in <p />.
****

<Popup
	content="the content"
	trigger="trigger"
/>


****
Use with just props as JSX.
****

<Popup
	content={<p>the content</p>}
	trigger={<p>the trigger</p>}
/>


****
Use with content as prop (string or JSX) and Trigger component.
****

<Popup content="string|jsx">
		<Trigger>
				here is the trigger
		</Trigger>
</Popup>


****
Hey, this is fun.
****

<Popup trigger="string|jsx">
		<Content>
				here is the content
		</Content>
</Popup>


****
Content and Trigger can be rendered as a different element.
Otherwise they will be <span className="trigger|content"></span>
****

<Popup>
		<Content element="marquee" className="my-marquee-class">
				here is the content
		</Content>
		<Trigger element="pre" className="my-pre-class">
				here is the trigger
		</Trigger>
</Popup>

****
Adding a showCloseIcon prop will allow this Popup to control
the open and closed state on it's own.  This will add a little
X icon in the top right of the popup for closing.  This should
probably be used in conjunction with on="click" for opening
the Popup initially.
****

<Popup
	content="the content"
	trigger="trigger"
	on="click"
	showCloseIcon
/>

*/

import React from 'react';
import PropTypes from 'prop-types';
import { Popup as SemanticPopup, Icon } from 'semantic-ui-react';
import './Popup.css';

export const Trigger = ({ children }) => <>{children}</>;
Trigger.displayName = 'Datacoral-Popup-Trigger';
Trigger.propTypes = {
  children: PropTypes.node,
};

export const Content = ({ children }) => <>{children}</>;
Content.displayName = 'Datacoral-Popup-Content';
Content.propTypes = {
  children: PropTypes.node,
};

export const Popup = class extends React.PureComponent {
  state = { open: false };
  popupContent;
  popupTrigger;
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  /**
   * Is it Component or string?
   */
  stringOrComponentCheck = (stringOrComponent) => {
    return typeof stringOrComponent === 'string' ? (
      <p>{stringOrComponent}</p>
    ) : (
      stringOrComponent
    );
  };
  /**
   * Use the element and className props from popupElement if available,
   * otherwise <span className={type}></span>
   */
  wrapper = (popupElement, type) => {
    const { element, className } = popupElement.props;
    const el = React.createElement(
      element || 'span',
      { className: className || type },
      popupElement,
    );
    if (type === 'content' && this.props.showCloseIcon) {
      return this.makeClose(el);
    }
    return el;
  };
  /**
   * Wrap el in the .showClose div with the close icon.
   */
  makeClose = (el) => (
    <div className="showClose">
      <Icon
        size={16}
        color="#C2C4C5"
        icon="close"
        className="popup-close pointer"
        onClick={this.handleClose}
      />
      {el}
    </div>
  );
  render() {
    const { content, trigger, children, showCloseIcon, ...rest } = this.props;
    const nodeCount = React.Children.count(children);
    if (nodeCount > 2) {
      // If children are used only allow a Trigger and a Content component.
      throw new Error('Popup may only have two children');
    }
    if (nodeCount) {
      React.Children.forEach(children, (child) => {
        if (child.type.displayName === 'Datacoral-Popup-Trigger') {
          // Matches our Trigger wrapper component
          this.popupTrigger = child;
        }
        if (child.type.displayName === 'Datacoral-Popup-Content') {
          // Matches our Content wrapper component
          this.popupContent = child;
        }
      });
    }
    // Final check for string or jsx prop vs Trigger & Content component.
    this.popupTrigger =
      this.popupTrigger || this.stringOrComponentCheck(trigger);
    this.popupContent =
      this.popupContent || this.stringOrComponentCheck(content);

    if (!this.popupTrigger || !this.popupContent) {
      // Dang, we're mising one of these guys.
      throw new Error('Popup must have a trigger and content');
    }

    // If we have showCloseIcon we need to manage
    // the open state ourselves.
    const additionalProps = showCloseIcon
      ? {
          open: this.state.open,
          onOpen: this.handleOpen,
          onClose: this.handleClose,
        }
      : {};

    // Pass our popupContent|Trigger into SemanticPopup, along
    // with any other props intended for SemanticPopup from {...rest}.
    return (
      <SemanticPopup
        className="Datacoral-popup"
        content={this.wrapper(this.popupContent, 'content')}
        trigger={this.wrapper(this.popupTrigger, 'trigger')}
        {...rest}
        {...additionalProps}
      />
    );
  }
};

Popup.defaultProps = {
  hideOnScroll: true,
};

Popup.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  showCloseIcon: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
};
