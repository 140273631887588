import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Popup } from '../Popup/Popup';

class SimpleTableSortableHeaders extends React.Component {
  state = { sortOn: null, asc: false, sortLabel: null };
  setSort(property, label, colIndex, order) {
    const sortOnLabel = this.state.sortOnLabel;
    const newState = Object.assign({}, this.state);
    if (order) {
      newState.asc = order === 'asc';
      newState.sortOn = property;
    } else {
      if (sortOnLabel === label) {
        newState.asc = !this.state.asc;
      } else {
        newState.asc = true;
        newState.sortOn = property;
      }
    }
    newState.sortOnLabel = label;
    this.setState(newState, () => this.sendSorter(colIndex));
  }
  componentDidMount() {
    this.defaultSort(this.props.tableKey);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.dataCount !== this.props.dataCount) {
      this.defaultSort(this.props.tableKey);
    }
  }
  /**
   * Set the default sort if tableKey and a default sort are available.
   * @param {string} tableKey
   */
  defaultSort(tableKey) {
    if (!tableKey || !this.sortEnabled()) {
      return;
    }
    const sort = this.getDefaultSort(tableKey);
    if (sort) {
      const { col, order } = this.parseIfString(sort);
      const field = this.props.columns[col];
      try {
        this.setSort(field.property, field.label, col, order);
      } catch {
        // This can occur when the number of columns does not match what has been saved in localStorage.
        // Swallow the error and leave the table in it's default sorting.
        // The next itme it is sorted the localStorage valus will be updated.
      }
    }
  }
  /**
   * Don't sort if dataCount is defined and dataCount < 2
   */
  sortEnabled() {
    const { dataCount } = this.props;
    return dataCount !== undefined ? dataCount > 1 : true;
  }
  /**
   * Parse string or return object.
   * @param {object|string} obj
   */
  parseIfString(obj) {
    return typeof obj === 'string' ? JSON.parse(obj) : obj;
  }
  /**
   * Check for defaultsort in localStorage or props.
   */
  getDefaultSort() {
    return localStorage.getItem(this.props.tableKey) || this.props.defaultSort;
  }
  /**
   * Save the sort in localStorage.
   * @param {string} tableKey - tableKey prop
   * @param {number} col - column index
   * @param {string} order - asc or desc
   */
  storeSort(tableKey, col, order) {
    localStorage.setItem(tableKey, JSON.stringify({ col, order }));
    if (this.props.onSortClick) {
      this.props.onSortClick(col, order);
    }
  }
  /**
   * Fire the parent table component's sorter function.
   * @param {number} colIndex
   */
  sendSorter(colIndex) {
    const sortOn = this.state.sortOn;
    if (!sortOn) {
      return;
    }
    const { tableKey } = this.props;
    const findProp =
      typeof sortOn === 'string'
        ? (item) => {
            const itemVal = item[sortOn];
            return typeof itemVal === 'string'
              ? itemVal.toLowerCase()
              : itemVal;
          }
        : sortOn;
    const ascSort = (a, b) =>
      findProp(a) < findProp(b) ? -1 : findProp(a) > findProp(b) ? 1 : 0;
    const descSort = (a, b) =>
      findProp(a) < findProp(b) ? 1 : findProp(a) > findProp(b) ? -1 : 0;
    if (tableKey) {
      this.storeSort(tableKey, colIndex, this.state.asc ? 'asc' : 'desc');
    }
    this.props.sorter(this.state.asc ? ascSort : descSort, colIndex);
  }
  render() {
    const { asc, sortOnLabel } = this.state;
    const sortEnabled = this.sortEnabled();
    const { tableKey } = this.props;

    return (
      <thead>
        <tr>
          {this.props.columns.map(
            (
              {
                label,
                content,
                property,
                className,
                isStatic,
                tooltip,
                tooltipProps = {},
                justify,
                width,
              },
              index,
            ) =>
              isStatic ? (
                <th
                  className={`${className || ''}`}
                  key={index}
                  style={width ? { width } : {}}>
                  {content || label}
                </th>
              ) : (
                <th
                  data-testid={`${tableKey}-${label.replace(/\s+/g, '-')}`}
                  key={index}
                  className={`sortable ${className || ''} ${
                    asc ? 'asc' : 'desc'
                  } ${sortEnabled && sortOnLabel === label ? 'sorted' : ''}`}
                  onClick={() =>
                    sortEnabled && this.setSort(property, label, index)
                  }
                  style={width ? { width } : {}}>
                  <div
                    className={`flex items-center ${
                      justify ? `justify-${justify}` : ''
                    }`}
                    track-id={`${tableKey}-${label}`}>
                    <div
                      data-testid={`column-value ${index}`}
                      className="value">
                      {tooltip ? (
                        <Popup
                          verticalOffset={-10}
                          position="bottom center"
                          trigger={content || label}
                          content={tooltip}
                          {...tooltipProps}
                        />
                      ) : (
                        content || label
                      )}
                    </div>
                    {sortEnabled && (
                      <div
                        className={`sort-indicator flex flex-column ml1 ${
                          sortOnLabel === label ? 'active' : ''
                        }`}>
                        {sortOnLabel === label ? (
                          asc ? (
                            <Icon size="small" name="caret down" />
                          ) : (
                            <Icon size="small" name="caret up" />
                          )
                        ) : (
                          <Icon size="small" name="caret down" />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ),
          )}
        </tr>
      </thead>
    );
  }
}

SimpleTableSortableHeaders.propTypes = {
  columns: PropTypes.array,
  sorter: PropTypes.func,
  tableKey: PropTypes.string,
  defaultSort: PropTypes.object,
  dataCount: PropTypes.number,
  onSortClick: PropTypes.func,
};

export default SimpleTableSortableHeaders;
