import PropTypes from 'prop-types';
import React from 'react';
import SimpleTableSortableHeaders from './SimpleTableSortableHeaders';
import useSelectableRows from './useSelectableRows';
import './SimpleTable.css';

/**
 * A dead simple, no frills HTML table.  Unless you use Sorting -- Sorting documented after simple usage.
 *
 * ***SIMPLE USAGE (no sorting):***
 *
 * Props:
 *  className: optional class name applied to the <table> element
 *  columns: String array
 *  columns Object array
 *      {label: string, width: string/number}
 *  data: String array
 *  data: JSX array
 *  data: Object array --> for the most part resorting to this means you should just use props.children
 *      {
 *          content: jsx/string,
 *          props: {
 *              onClick(){alert('these props are added to the td')}
 *          }
 *      }
 *  children: JSX,
 *  sorter: function - for sortable usage
 *  dataCount: number - for sortable usage
 *
 * Usage:
 *
 * With data:
 *
 *  COLUMNS:
 *  const columns = ['a', 'b']
 *  ** OR **
 *  const columns = [{label: 'a', width: 50}, {label: 'a', width: '10%'}]
 *
 *  DATA:
 *  const data = [
 *      ['1-a-data', '1-b-data'],
 *      ['2-a-data', '2-b-data']
 *  ]
 *  ** OR **
 *  const data = [
 *      [
 *          {
 *              content: '1-a-data',
 *              props: {onClick(){ alert('hello world')}},
 *          },
 *          {
 *              content: '1-b-data',
 *              props: {onClick(){ alert('hello world')}},
 *          }
 *      ],
 *  ]
 *  <SimpleTable columns={columns} data={data} />
 *
 * With children:
 *
 * <SimpleTable columns={columns}>
 *  <tbody>
 *      <jsx-here />
 *  </tbody>
 * </SimpleTable>
 *
 *
 *
 * ***SORTABLE USAGE:***
 *
 * There are no changes to the tbody, all props and options are identical.
 * `sorter` must be set on <SimpleTable sorter={sorterFn}>
 * `columns` must be provided as a prop.
 * `dataCount`- optional, turns off sorting if < 2
 *
 * columns: Array
 * example:
 *
 * 	[{
 *     label: string, // the string to show in the header cell.
 *     content: alternative to label, can be used for more complex markup, but label is still needed
 *              in order to render the sorted UI properly.
 *     isStatic: bool // true = not sortable
 *     className: string, // className applied to the header cell
 *     property: string or function
 *               string = a simple sort on a string available at the root of the data being sorted
 *               function = a function that follows this pattern to sort on a nested property value
 *                          item => item.nested.property
 *                          this is also useful for transforming data
 *                          item => moment().diff(item.timestamp)
 *     tooltip: string, //string for browser tooltip in the header cell
 *     justify: string // flexbox justify value
 * 	}]
 *
 * tableKey: string (optional)
 *  this value will be used to store the current sort in localStorage.
 *
 * defaultSort: object ({col: number, order: 'asc|desc})
 *  To be used in conjunction with tableKey, this will set an initial sort.
 *
 *
 *
 */

const SimpleTable = ({
  columns,
  data,
  children,
  sorter,
  tableKey,
  defaultSort,
  dataCount,
  onSortClick,
  testId,
  className = null,
  selectable,
  onSelect,
  selected,
}) => {
  let thead = null;
  let tbody = null;
  const tableColumns = columns?.slice();
  const elements = React.Children.toArray(children) || [];
  if (elements.length === 2) {
    thead = elements[0];
    tbody = elements[1];
  } else {
    if (elements[0]?.type === 'thead') {
      thead = elements[0];
    }
    if (elements[0]?.type === 'tbody') {
      tbody = elements[0];
    }
    if (elements.length === 1 && !thead && !tbody) {
      throw new Error('SimpleTable JSX only allows for a thead and/or a tbody');
    }
  }

  const {
    columnData,
    rowData,
    theadComponent: thcomp,
    tbodyComponent,
  } = useSelectableRows({
    selectable,
    thead,
    tbody,
    rowData: data,
    columnData: tableColumns,
    onSelect,
    selected,
  });

  let theadComponent = thcomp;

  if (sorter && columnData) {
    theadComponent = (
      <SimpleTableSortableHeaders
        dataCount={dataCount}
        tableKey={tableKey}
        defaultSort={defaultSort}
        columns={columnData}
        sorter={sorter}
        onSortClick={onSortClick}
      />
    );
  }

  return (
    <table
      data-testid={testId}
      className={`twenty-seven-table ${className ? className : ''}`}>
      {theadComponent ? (
        theadComponent
      ) : columnData ? (
        <thead>
          <tr>
            {columnData.map((c, i) => (
              <th
                data-testid={
                  typeof c === 'string'
                    ? c.toLowerCase().replace(/ /g, '_')
                    : c.label.toLowerCase().replace(/ /g, '_')
                }
                style={
                  typeof c === 'string'
                    ? null
                    : c.width
                    ? { width: c.width }
                    : null
                }
                className={typeof c !== 'string' ? c?.className : ''}
                key={i}>
                {typeof c === 'string' ? c : c.content ? c.content : c.label}
              </th>
            ))}
          </tr>
        </thead>
      ) : null}
      {tbodyComponent ? (
        tbodyComponent
      ) : (
        <tbody>
          {rowData &&
            rowData.map((d, i) => (
              <tr key={i}>
                {d.map((item, j) =>
                  typeof item === 'object' && item.hasOwnProperty('content') ? (
                    <td key={j} {...item.props}>
                      {item.content}
                    </td>
                  ) : (
                    <td key={j}>{item}</td>
                  ),
                )}
              </tr>
            ))}
        </tbody>
      )}
    </table>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.node,
  sorter: PropTypes.func,
  tableKey: PropTypes.string,
  defaultSort: PropTypes.object,
  dataCount: PropTypes.number,
  onSortClick: PropTypes.func,
  testId: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
};

export default SimpleTable;
